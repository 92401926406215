import { Session } from 'next-auth'

import { Role } from '@elendi/util-shared-constants'

const MIXPANEL_TOKEN = process.env['NEXT_PUBLIC_MIXPANEL_TOKEN']
export const IS_TRACKING_ENABLED = false

export const initMixpanel = () => {
  if (IS_TRACKING_ENABLED && MIXPANEL_TOKEN) {
    console.log(`mixpanel.init(MIXPANEL_TOKEN, {
      ignore_dnt: true,
      debug: process.env['NEXT_PUBLIC_VERCEL_ENV'] !== 'production',
      disable_cookie: true,
      api_host: '/mp',
    })`)
  }
}

export const mixpanelIdentificator = (session: Session | null, role: Role) => {
  if (IS_TRACKING_ENABLED) {
    if (session?.user?.email) {
      const email = session?.user?.email
      console.log(`mixpanel.identify(email)`)
      console.log(`mixpanel.people.set({ $name: session.user.name, $email: email })`)
      console.log(`mixpanel.people.set('role', titleCase(role))`)
      console.log(`mixpanel.people.set('company', titleCase(guessCompanyId(email)))`)
    }
  }
}

export const mixpanelIdentifyWithEmail = (email: string) => {
  if (IS_TRACKING_ENABLED) console.log(`mixpanel.identify(email)`)
}

export const associateEmailToMixpanelUser = (newEmail: string, oldEmail: string) => {
  if (IS_TRACKING_ENABLED) {
    console.log(`mixpanel.alias(oldEmail, newEmail)`)
  }
}
