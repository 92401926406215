import { polarToCartesian } from '@elendi/util-helpers'

// https://stackoverflow.com/a/18473154/17604148
export const Arc = ({
  x,
  y,
  radius,
  startAngle,
  endAngle,
  strokeWidth,
  stroke,
}: {
  x: number
  y: number
  radius: number
  startAngle: number
  endAngle: number
  strokeWidth: number
  stroke: string
}) => {
  const start = polarToCartesian(x, y, radius, endAngle)
  const end = polarToCartesian(x, y, radius, startAngle)
  const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1'
  const d = ['M', start.x, start.y, 'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y].join(' ')
  return <path d={d} fill='none' stroke={stroke} strokeWidth={strokeWidth} />
}
