import { Arc } from '@elendi/ui-svg'

export const CircularLoader = ({
  color,
  bgColor,
  size,
  thin = false,
}: {
  color?: string
  bgColor?: string
  size?: number
  thin?: boolean
}) => {
  const svgSize = thin ? 124 : 24
  const loaderWidth = 4
  const loaderCenterPosition = svgSize / 2
  const loaderRadius = (svgSize - loaderWidth) / 2
  return (
    <svg
      className='animate-spin'
      style={
        size
          ? { width: `${size}px`, height: `${size}px` }
          : {
              lineHeight: '1.5rem',
              fontSize: '1rem',
              height: '1.5rem',
              width: '1.5rem',
            }
      }
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox={`0 0 ${svgSize} ${svgSize}`}
    >
      <circle
        className={bgColor ? '' : 'opacity-25'}
        cx={loaderCenterPosition}
        cy={loaderCenterPosition}
        r={loaderRadius}
        stroke={bgColor || color || 'currentColor'}
        strokeWidth={`${loaderWidth}`}
      ></circle>
      <Arc
        x={loaderCenterPosition}
        y={loaderCenterPosition}
        radius={loaderRadius}
        startAngle={0}
        endAngle={60}
        strokeWidth={loaderWidth}
        stroke={color || 'currentColor'}
      />
    </svg>
  )
}
