import { signIn, signOut } from 'next-auth/react'

import { IS_TRACKING_ENABLED } from '@elendi/util-tracking'

const provider = 'auth0'
const signOutCallbackUrl = `/api/auth/logout`

export const defaultSignIn = ({
  prefill,
  callbackUrl,
}: {
  prefill?: string
  callbackUrl?: string
} = {}) => {
  const auth0Options: Record<string, string> = {}
  if (prefill && prefill !== 'undefined') auth0Options['login_hint'] = prefill
  signIn(provider, { callbackUrl }, auth0Options)
}

export const signInRedirect = (callbackUrl: string) => {
  signIn(provider, { callbackUrl })
}

export const defaultSignUp = ({
  prefill,
  callbackUrl,
}: {
  prefill?: string
  callbackUrl?: string
} = {}) => {
  const auth0Options: Record<string, string> = {}
  auth0Options['screen_hint'] = 'signup'
  if (prefill && prefill !== 'undefined') auth0Options['login_hint'] = prefill
  signIn(provider, { callbackUrl }, auth0Options)
}

export const defaultSignOut = () => {
  try {
    if (IS_TRACKING_ENABLED) {
      //mixpanel.reset()
    }
  } catch (error) {
    console.log('error')
  }
  signOut({ callbackUrl: signOutCallbackUrl })
}

export enum Role {
  LOGGED_OUT = 'LOGGED_OUT',
  UNVERIFIED = 'UNVERIFIED',
  USER = 'USER',
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
  SUPER_MANAGER = 'SUPER_MANAGER',
  HR = 'HR',
  PEER = 'PEER',
}
