import useTranslation from 'next-translate/useTranslation'

import { CircularLoader } from '@elendi/ui-loaders'

export const Skeleton = () => {
  const { t } = useTranslation()
  return (
    <div className='flex flex-col justify-center items-center w-screen h-screen space-y-10 dark:bg-dark'>
      <CircularLoader size={120} color='#d03737' bgColor='#ffffff' thin />
      <p className='text-sm text-text-light'>{t('common:skeleton.loading-message')}</p>
    </div>
  )
}
