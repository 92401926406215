export const remap = (
  x: number,
  sourceMin: number,
  sourceMax: number,
  targetMin: number,
  targetMax: number,
): number => targetMin + ((x - sourceMin) * (targetMax - targetMin)) / (sourceMax - sourceMin)

export const clamp = (x: number, min: number, max: number) => {
  if (min > max) [min, max] = [max, min]
  if (x < min) return min
  if (x > max) return max

  return x
}

export const polarToCartesian = (
  centerX: number,
  centerY: number,
  radius: number,
  angleInDegrees: number,
) => ({
  x: centerX + radius * Math.cos((angleInDegrees * Math.PI) / 180),
  y: centerY + radius * Math.sin((angleInDegrees * Math.PI) / 180),
})

export const roundedToFixed = (input: number, digits: number) => {
  if (isNaN(input)) return NaN
  const rounded = Math.pow(10, digits)
  return Number((Math.round(input * rounded) / rounded).toFixed(digits))
}

export const roundedOneDecimal = (input: number) => roundedToFixed(input, 1)

export const reRemap = (x: number, source: [number, number], target: [number, number]): number => {
  if (source[0] === source[1]) return NaN
  const targetMin = Math.min(...target)
  const targetMax = Math.max(...target)
  const sourceMin = Math.min(...source)
  const sourceMax = Math.max(...source)
  return targetMin + ((x - sourceMin) * (targetMax - targetMin)) / (sourceMax - sourceMin)
}
